import React from "react";
import "../../Assets/css/case.css";
import "../../Assets/css/base.css";
import "../../Assets/css/demo1.css";
import "../../Assets/css/home.css";
import SEO from "../../components/seo";

import Parallax from "react-rellax";
import $ from "jquery";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Header from "../../components/header";
import Footer from "../../components/footer";

class Octrino extends React.Component {
  componentDidMount() {
    require("paroller.js");
    $(document).ready(function() {
      $(".paroller-example").paroller({
        factor: 0.15,
        type: "foreground",
        direction: "vertical"
      });
    });
  }

  render() {
    return (
      <div>
        <SEO
          title="Octrino.com · upgrading SEO, Netlinking, web editoring to the next level."
          description="Project management, web editorial, advanced SEO and
                    Net-linking on a single platform"
          image={
            "https://makook.space" +
            require("../../images/Octrino/octrino-cover.jpg")
          }
        />
        <Header />

        <div
          data-post-title="makookcase"
          data-theme="theme-dark"
          className="theme-dark"
        >
          <main className="case makookcase">
            <h1 className="master-title">Octrino</h1>

            <div
              className="background-color-makookcase-1 color-transition"
              data-color="#000"
            >
              <section className="image-block sticky-title-padding-top mobile-hero no-margin-bottom sticky-stop">
                <figure>
                  <Img fluid={this.props.data.imageOne.childImageSharp.fluid} />
                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className=" desktop-only">
                      Upgrading Net-linking and content creation for websites
                    </p>
                  </Parallax>

                  <Parallax speed={-2} percentage={0.5} centered>
                    <p className="mobile-only">
                      Upgrading Net-linking and content creation for websites
                    </p>
                  </Parallax>
                </figure>
              </section>

              <section className="image-text push-up half-padding-bottom no-margin-bottom">
                <figure>
                  <Img fluid={this.props.data.imageTwo.childImageSharp.fluid} />
                </figure>
                <article>
                  <span>Date</span>
                  <p>2019</p>
                  <span>Challenge</span>
                  <p>
                    Project management, web editorial, advanced SEO and
                    Net-linking on a single platform to be designed from the
                    ground up in short notice, a piece of design that needed to
                    look and behave better than all the competition in France.
                    That was the challenge given by our ambitious client. These
                    kind of systems are usually cluttered, cumbersome and poorly
                    designed which results in a bad experience to the end user.
                    This in­sight led to the per­haps blunt, but to the point
                    brief: Design the future of net-linking and web editorial
                    platforms.
                  </p>
                  <span>Solution</span>
                  <p>
                    With a well defined goal in sight we taped first in to the
                    vast experience of our client to develop an approach on how
                    a modern take on these kind of solutions should affect the
                    end user experience that could redefine the industry
                    functionality. On this foundation we ideated and benchmarked
                    our findings with functional prototypes tested in real world
                    scenarios. From this, we set to design the actual final
                    product and con­text­ual­ize our persona based ap­proach
                    with an honest and pur­pose­ful modern design system which
                    was op­ti­mised for max­imum mod­ul­arity, scalab­il­ity and
                    usa­bil­ity. in this endeavour, we designed everything from
                    scratch respecting the client’s existing brand identity and
                    expand on it.
                  </p>
                  <span>Disciplines</span>
                  <p>
                    Design System
                    <br />
                    User Experience
                    <br />
                    User Research
                    <br />
                    Prototyping
                    <br />
                    Testing
                  </p>
                </article>
              </section>

              <section className="mobile-text mobile-only">
                <article>
                  <div>
                    <span>Date</span>
                    <p>2016 – 2018</p>
                  </div>
                  <div>
                    <span>Challenge</span>
                    <p>
                      Project management, web editorial, advanced SEO and
                      Net-linking on a single platform to be designed from the
                      ground up in short notice, a piece of design that needed
                      to look and behave better than all the competition in
                      France. That was the challenge given by our ambitious
                      client. These kind of systems are usually cluttered,
                      cumbersome and poorly designed which results in a bad
                      experience to the end user. This in­sight led to the
                      per­haps blunt, but to the point brief: Design the future
                      of net-linking and web editorial platforms.
                    </p>
                  </div>
                  <div>
                    <span>Solution</span>
                    <p>
                      With a well defined goal in sight we taped first in to the
                      vast experience of our client to develop an approach on
                      how a modern take on these kind of solutions should affect
                      the end user experience that could redefine the industry
                      functionality. On this foundation we ideated and
                      benchmarked our findings with functional prototypes tested
                      in real world scenarios. From this, we set to design the
                      actual final product and con­text­ual­ize our persona
                      based ap­proach with an honest and pur­pose­ful modern
                      design system which was op­ti­mised for max­imum
                      mod­ul­arity, scalab­il­ity and usa­bil­ity. in this
                      endeavour, we designed everything from scratch respecting
                      the client’s existing brand identity and expand on it.
                    </p>
                  </div>
                  <div>
                    <span>Disciplines</span>
                    <p>
                      Design System
                      <br />
                      User Experience
                      <br />
                      User Research
                      <br />
                      Prototyping
                      <br />
                      Testing
                      <br />
                    </p>
                  </div>
                </article>
              </section>
            </div>

            <div
              className="background-color-makookcase-2 color-transition"
              data-color="#1b1b1b"
            >
              <section className="image-block half-margin-bottom">
                <figure>
                  <Img
                    fluid={this.props.data.imageThree.childImageSharp.fluid}
                  />
                </figure>
              </section>

              <section className="makookcase-block-1">
                <h3 className="paroller-example paroller-title desktop-only">
                  A streamlined All in One system with persona-based interfaces
                  and industry-changing functionality.
                </h3>
              </section>

              <section className="image-block">
                <figure className="">
                  <Img
                    fluid={this.props.data.imageFour.childImageSharp.fluid}
                  />
                </figure>
              </section>

              <section className="image-text half-margin-bottom">
                <figure>
                  <Img
                    fluid={this.props.data.imageFive.childImageSharp.fluid}
                  />

                  <Parallax speed={2}>
                    <h3 className=" mobile-only">
                      An honest and purposeful design system that allows users
                      to focus on what’s most important — their websites and
                      content.
                    </h3>
                  </Parallax>
                </figure>
                <article>
                  <Parallax speed={-2} percentage={0.5} centered>
                    <h3 className=" desktop-only" data-func="caseIntroParallax">
                      An honest and purposeful design system that allows users
                      to focus on what’s most important — their websites and
                      content.
                    </h3>
                  </Parallax>
                </article>
              </section>

              <section className="image-block">
                <figure>
                  <Img fluid={this.props.data.imageSix.childImageSharp.fluid} />
                </figure>
              </section>

              <section className="two-images mobile-margin-hack half-margin-bottom">
                <figure className="">
                  <Img
                    fluid={this.props.data.imageSeven.childImageSharp.fluid}
                  />
                </figure>
                <figure className="">
                  <Img
                    fluid={this.props.data.imageEight.childImageSharp.fluid}
                  />
                </figure>
              </section>

              <section className="two-images">
                <figure className="">
                  <Img
                    fluid={this.props.data.imageNine.childImageSharp.fluid}
                  />
                </figure>
                <figure className="">
                  <Img fluid={this.props.data.imageTen.childImageSharp.fluid} />
                </figure>
              </section>

              <Footer />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default Octrino;

export const OctrinoImg = graphql`
  fragment OctrinoImg on File {
    childImageSharp {
      fluid(maxWidth: 1400, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const OctrinoQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "Octrino/octrino-cover.jpg" }) {
      ...fluidImage
    }

    imageTwo: file(relativePath: { eq: "Octrino/octrinomycommands.jpg" }) {
      ...fluidImage
    }

    imageThree: file(
      relativePath: { eq: "Octrino/otrino-My-projects-screen.jpg" }
    ) {
      ...fluidImage
    }

    imageFour: file(
      relativePath: { eq: "Octrino/Octrino-Netlinking-campagne.jpg" }
    ) {
      ...fluidImage
    }

    imageFive: file(
      relativePath: { eq: "Octrino/Project-and-finacial-info-screen.jpg" }
    ) {
      ...fluidImage
    }

    imageSix: file(relativePath: { eq: "Octrino/compagnes-netlinking.png" }) {
      ...fluidImage
    }

    imageSeven: file(relativePath: { eq: "Octrino/Page-du-Profile.jpg" }) {
      ...fluidImage
    }

    imageEight: file(relativePath: { eq: "Octrino/Crediter.png" }) {
      ...fluidImage
    }

    imageNine: file(relativePath: { eq: "Octrino/Planifier-un-article.png" }) {
      ...fluidImage
    }

    imageTen: file(relativePath: { eq: "Octrino/Project-creation-Done.png" }) {
      ...fluidImage
    }
  }
`;
